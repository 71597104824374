import { withErrorBoundary } from 'components/ErrorBoundary';
import CustomLink from 'components/Globals/Base/Link';
import { useRouter } from 'next/router';
import { EventData } from 'services/Gtm/entities';
import { UIFragments } from 'utils/log/constants/uiFragments';
import getEarningReportPageTestId from 'constants/testsIds/earningsReportPage';
import { SocialLinksContainer, SocialLinkContainer } from './SocialLinksStyles';

interface SocialLinksProps {
  title: string;
  className?: string;
  usedInHeader?: boolean;
  trackingData?: EventData;
  isListPage?: boolean;
}

const SocialLinks = ({ title, className, usedInHeader = false, trackingData, isListPage }: SocialLinksProps) => {
  const router = useRouter();
  const url = `https://fortune.com${router.asPath}`;

  const facebookUrl = url + (isListPage ? '?utm_source=ranking&utm_medium=facebook&utm_campaign=social_share' : '');
  const twitterUrl = url + (isListPage ? '?utm_source=ranking&utm_medium=x&utm_campaign=social_share' : '');
  const linkedinUrl = url + (isListPage ? '?utm_source=ranking&utm_medium=linkedin&utm_campaign=social_share' : '');
  const emailUrl = url + (isListPage ? '?utm_source=ranking&utm_medium=email&utm_campaign=social_share' : '');

  const encodedFbUrl = encodeURIComponent(facebookUrl);
  const encodedTwUrl = encodeURIComponent(twitterUrl);
  const encodedLiUrl = encodeURIComponent(linkedinUrl);
  const encodedEmailUrl = encodeURIComponent(emailUrl);

  const encodedTitle = encodeURIComponent(title);

  return (
    <SocialLinksContainer className={className}>
      <SocialLinkContainer>
        <CustomLink
          data-cy={getEarningReportPageTestId('FACEBOOK_ICON')}
          className='social facebook-square'
          ariaLabel='go to facebook'
          target='_blank'
          rel='noopener noreferrer'
          href={
            usedInHeader ?
              'https://www.facebook.com/FortuneMagazine/' :
              `https://www.facebook.com/sharer.php/?u=${encodedFbUrl}`
          }
          trackingData={
            trackingData && {
              ...trackingData,
              eventAction: `${trackingData.eventAction} Facebook`,
              eventLabel: `${
                usedInHeader ?
                  'https://www.facebook.com/FortuneMagazine/' :
                  `https://www.facebook.com/sharer.php/?u=${encodedFbUrl}`
              }}`,
            }
          }
        />
      </SocialLinkContainer>
      <SocialLinkContainer>
        <CustomLink
          data-cy={getEarningReportPageTestId('X_ICON')}
          className='social twitter'
          ariaLabel='go to twitter'
          target='_blank'
          rel='noopener noreferrer'
          href={
            usedInHeader ?
              'https://twitter.com/FortuneMagazine/' :
              `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedTwUrl}`
          }
          trackingData={
            trackingData && {
              ...trackingData,
              eventAction: `${trackingData.eventAction} Twitter`,
              eventLabel: `${
                usedInHeader ?
                  'https://twitter.com/FortuneMagazine/' :
                  `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedTwUrl}`
              }}`,
            }
          }
        />
      </SocialLinkContainer>
      <SocialLinkContainer>
        <CustomLink
          data-cy={getEarningReportPageTestId('LINKEDIN_ICON')}
          className='social linkedin'
          ariaLabel='go to linkedin'
          target='_blank'
          rel='noopener noreferrer'
          href={
            usedInHeader ?
              'https://www.linkedin.com/company/fortune-magazine/' :
              `https://www.linkedin.com/shareArticle/?url=${encodedLiUrl}`
          }
          trackingData={
            trackingData && {
              ...trackingData,
              eventAction: `${trackingData.eventAction} LinkedIn`,
              eventLabel: `${
                usedInHeader ?
                  'https://www.linkedin.com/company/fortune-magazine/' :
                  `https://www.linkedin.com/shareArticle/?url=${encodedLiUrl}`
              }}`,
            }
          }
        />
      </SocialLinkContainer>
      {!usedInHeader && (
        <SocialLinkContainer>
          <CustomLink
            data-cy={getEarningReportPageTestId('EMAIL_ICON')}
            className='social email'
            ariaLabel='send email'
            target='_blank'
            rel='noopener noreferrer'
            href={`mailto:?subject=${title}&body=${encodedEmailUrl}`}
            trackingData={
              trackingData && {
                ...trackingData,
                eventAction: `${trackingData.eventAction} Mail`,
                eventLabel: `mailto:?subject=${title}&body=${encodedEmailUrl}`,
              }
            }
          />
        </SocialLinkContainer>
      )}
    </SocialLinksContainer>
  );
};

export default withErrorBoundary(SocialLinks, { fragment: UIFragments.SOCIALS });

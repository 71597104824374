const EARNING_REPORT_PAGE_TEST_IDS = {
  AUTHOR_SPEAKER_TEXT: 'author-speaker-text',
  BACK_ICON: 'back-icon',
  BACK_LINK: 'back-link',
  CONTENT_WITH_EMBED: 'content-with-embed',
  DESKTOP_SOCIAL_SHARE_CONTAINER: 'desktop-social-share-container',
  EMAIL_ICON: 'email-icon',
  EXIT_FULL_SCREEN_BUTTON: 'exit-full-screen-button',
  FACEBOOK_ICON: 'facebook-icon',
  FULL_SCREEN_BUTTON: 'full-screen-button',
  HEADER_LINE: 'header-line',
  INTRO: 'intro',
  INTRO_DATE: 'intro-date',
  INTRO_EARNING_REPORT: 'intro-earning-report',
  INTRO_TITLE: 'intro-title',
  LINKEDIN_ICON: 'linkedin-icon',
  MOBILE_SOCIAL_SHARE_CONTAINER: 'mobile-social-share-container',
  NEXT_PAGE_BUTTON: 'next-page-button',
  OVERLAY: 'overlay',
  PARTICIPANTS_CONTAINER: 'participants-container',
  PARTICIPANTS_HEADER: 'participants-header',
  PARTICIPANT_NAME: 'participant-name',
  PDF_VIEWER_BUTTONS_CONTAINER: 'pdf-viewer-buttons-container',
  PDF_VIEWER_CONTAINER: 'pdf-viewer-container',
  POWERED_BY: 'powered-by',
  PREVIOUS_PAGE_BUTTON: 'previous-page-button',
  QUARTR_CONTAINER: 'qurtr-container',
  QUARTR_ICON: 'quartr-icon',
  SHARE_ICON: 'share-icon',
  SPEAKER_TEXT: 'speaker-text',
  TOP_GROUP: 'top-group',
  TRINITY_PLAYER_CONTAINER: 'trinity-player-container',
  X_ICON: 'x-icon',
  ZOOM_IN_BUTTON: 'zoom-in-button',
  ZOOM_OUT_BUTTON: 'zoom-out-button',
  ZOOM_POPOVER: 'zoom-popover',
};

export type TestIds = {
  [key in keyof typeof EARNING_REPORT_PAGE_TEST_IDS]?: string;
};

const getEarningReportPageTestId = (value: keyof TestIds) => EARNING_REPORT_PAGE_TEST_IDS[value];

export default getEarningReportPageTestId;

import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getIcon } from 'styles/icons';
import { getColor, Theme } from 'styles/theme';

export const SocialLinksContainer = styled.ul<{ theme: Theme }>`
  border-bottom: 1px solid ${getColor('borderSecondary')};
  display: inline-flex;
  flex-direction: row;
  padding: 0px 4px 4px 4px;

  @media (${breakpoint.mdMin}) {
    border-bottom: none;
    border-right: 1px solid ${getColor('borderSecondary')};
    flex-direction: column;
    padding: 4px 8px 4px 0px;
    position: sticky;
    right: 0;
    top: 125px;
  }
`;

export const SocialLinkContainer = styled.li<{ theme: Theme }>`
  margin-right: 16px;

  @media (${breakpoint.mdMin}) {
    margin-right: 0px;
    margin-bottom: 16px;
  }

  .social {
    ${getIcon('svg-states')};
  }

  .email {
    &::before {
      ${getIcon('email')};
    }
  }

  .facebook-square {
    &::before {
      ${getIcon('facebook-square')};
    }
  }

  .twitter {
    &::before {
      ${getIcon('twitter')};
    }
  }

  .linkedin {
    &::before {
      ${getIcon('linkedin')};
    }
  }

  &:last-child {
    margin-right: 0px;

    @media (${breakpoint.mdMin}) {
      margin-bottom: 0px;
    }
  }
`;
